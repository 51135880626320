<template>
    <table style="width: 100%">
        <template v-for="list in menu" :key="'menu_category_'+list.code">
            <tr v-if="toggled">
                <td colspan="2" class="nav__title">{{list.name}}</td>
            </tr>
            <template v-for="item in list.forms" :key="'_main-menu-'+menu.name">
                <tr v-if="item.isVisible" style="cursor:pointer;"
                    @click="menuState(item)"
                    :style="item.code === this.$route.params.form ? 'background-color: #F2F6FD;' : ''" >
                    <td :style="toggled ? 'padding: 2px 0px 2px 21px; vertical-align: middle;':'width: 100%; text-align: center; padding: 2px 0px; vertical-align: middle;'">
                        <div :style="toggled ? 'padding: 0 10px 0 40px;' : 'padding: 5px;'">
                            <font-awesome-icon
                                    :icon="['fas', item.icon]"
                                    :style="{ color: item.code === this.$route.params.form ? '#224A42' : 'grey' }"
                                    size="xl"
                            />
                        </div>


                        <!--<img :src="require('@/assets/img/svg/Star-outline.svg')" style="padding: 10px;" :style="item.code === this.$route.params.form ? 'background-color: #F2F6FD; border-radius: 50%;' : ''">-->
                    </td>
                    <td v-if="toggled" style="padding: 10px 21px 10px 0px;" :style=" item.code === this.$route.params.form ? 'color: #224A42; font-weight: 500;' : ''">
                        {{item.name}}
                    </td>
                </tr>
            </template>
        </template>
    </table>
</template>

<script>
    import {mapActions, mapMutations} from "vuex";
    import {FETCH_MENU_ACTION} from "@/store/storeconstants";

    export default {
        name: "TableMenu",
        props:{
            toggled:{type:Boolean},
            activeMenu: {type: Object}
        },
        beforeMount() {
            this.fetch_menu().then((ab) => {
                this.menu = ab.data?.navbar;
            })
        },
        data() {
          return {
              menu: []
          }
        },
        methods: {
            ...mapActions('menus', {
                fetch_menu: FETCH_MENU_ACTION
            }),
            ...mapMutations(['SET_LOADING']),

            menuState(item){
                if (item?.type === "plain") {
                    this.openForm(item.code)
                } else if (item?.type === "report") {
                    this.openReport(item.code)
                }
            },
            openForm(code) {
                this.SET_LOADING(true)
                this.$router.push({
                    name:'forms',
                    params: {form: code}
                })
            },
            openReport(code) {
                this.SET_LOADING(true)
                this.$router.push({
                    name:'reports',
                    params: {form: code}
                })
            }

        }

    }
</script>

<style scoped>

    .show__down-icon{
        position: relative;
        right: 5px;
        top: 0px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #3F3F3F;
        border-left: 2px solid #3F3F3F;
        transform: rotate(-45deg);
    }
    .show__up-icon{
        transform: rotate(-225deg);
        top: 0;
    }
    .nav__title {
        margin: 10px 0 5px 25px;
        font-weight: bold;
        padding: 8px 8px 8px 20px;
        text-transform: uppercase;
    }
</style>
