export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привет"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почта"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкции"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнить"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])}
}