export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salem"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтасы"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқаулар"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выполнить"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])}
}