export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello!!"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["card"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruction"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generate"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
}