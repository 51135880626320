export default [
    {
        path: 'forms/:form',
        name: 'forms',
        meta: {
            auth : true,
            onMenu: true,
            childOnMenu: true,
            title: 'Форма'
        },
        component: () => import('@/views/forms/PlainFormWrapper')
    },
    {
        path: 'reports/:form',
        name: 'reports',
        meta: {
            auth : true,
            onMenu: true,
            childOnMenu: true,
            title: 'Отчёт'
        },
        component: () => import('@/views/forms/ReportFromWrapper')
    },
    {
        path: 'forms/:form/config',
        name: 'form_config',
        meta: {
            auth : true,
            onMenu: false,
            childOnMenu: true,
            title: 'Добавить'
        },
        component: () => import('@/views/forms/FormGenerate')
    },
    {
        path: 'forms/:form/view',
        name: 'form_view',
        meta: {
            auth : true,
            onMenu: false,
            childOnMenu: true,
            title: 'Просмотр'
        },
        component: () => import('@/views/forms/ViewFormGenerate')
    },
]
