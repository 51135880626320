import axios from "axios";
import i18n from "@/services/i18n";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT

axios.interceptors.request.use(config => {
    config.headers['Accept-Language'] = i18n.global.locale || 'ru';
    return config;
}, error => {
    return Promise.reject(error);
});