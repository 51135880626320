<template>
  <div class="toast" :class="[type, { 'show': visible }]">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    type: {
      type: String,
      default: 'error'
    },
    duration: {
      type: Number,
      default: 3000 // время по умолчанию для отображения в миллисекундах
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
      setTimeout(() => {
        this.hide();
      }, this.duration);
    },
    hide() {
      this.visible = false;
    }
  },
  mounted() {
    this.show();
  }
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fb3838; /* Default background color for error type */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.toast.success {
  background-color: green;
}

.toast.show {
  opacity: 1;
}
</style>
