import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/services/axios";
import '@/assets/css/style.css';
import i18n from '@/services/i18n';
import Camera from "simple-vue-camera";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

// Добавляем иконки в библиотеку Font Awesome
library.add(fas);

// Создаем приложение Vue
const app = createApp(App);

// Глобальный обработчик ошибок
app.config.errorHandler = function (err, vm, info) {
    vm.$emit('error-occurred', { err, info });
};

// Используем компоненты и плагины
app.use(i18n)
    .use(store)
    .use(router)
    .component("camera", Camera)
    .component('font-awesome-icon', FontAwesomeIcon);

// Монтируем приложение
app.mount('#app');
