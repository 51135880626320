import { createRouter, createWebHistory } from 'vue-router'
import i18n from "../services/i18n";
import store from '@/store/index';
import {IS_USER_AUTHENTICATE_GETTER} from "@/store/storeconstants";
import form from "@/router/modules/form";

const routes = [
  {
    path: '/:locale',
    name: 'language',
    meta: {
      description: { ru:'',
        en:'',
        qaz:'',  },
      title: 'Панель управление',
      auth:true
    },
    component: () => import('@/views/layout/MainLayout.vue'),
    beforeEnter: (to, from, next) => {
      const locale1 = to.params.locale
      const supported_locales = ['kz','ru','en'] //process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
      if(!supported_locales.includes(locale1)) return next('ru')
      if(i18n.global.locale !== locale1){
        i18n.global.locale = locale1
      }
      return next()
    },
    children:[
        ...form
    ],
  },
  {
    path: '/',
    name: 'all',
    meta: { layout: 'empty', auth:true },
    redirect(){
      return '/ru/'
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty', auth:false },
    component: () => import('../views/Login')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  if('auth' in to.meta && to.meta.auth && !store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]){
    next('/login');
  }else if ('auth' in to.meta && !to.meta.auth && store.getters[`auth/${IS_USER_AUTHENTICATE_GETTER}`]){
    next('');
  }else {
    next();
  }
});

export default router
