import { createStore } from 'vuex'
import auth from './modules/auth';
import products from "@/store/modules/product";
import categories from "@/store/modules/category";
import history from "@/store/modules/history";
import permissions from "@/store/modules/permission";
import roles from "@/store/modules/role"
import users from "@/store/modules/user"
import menus from "@/store/modules/menu"
import forms from "@/store/modules/forms"
import dictionary from "@/store/modules/dictionary"
export default createStore({
  state: {
    isLoad: false
  },
  getters: {
  },
  mutations: {
    SET_LOADING(state, payload){
      state.isLoad = payload;
    },
  },
  actions: {
  },
  modules: {
    auth,
    products,
    history,
    categories,
    permissions,
    roles,
    users,
    menus,
    forms,
    dictionary
  }
})
