<template>
  <div class="loader_wrapper" v-if="isLoad">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    isLoad: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.loader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Тень на заднем плане */
  z-index: 9999; /* Лоадер поверх всех окон */
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #224A42;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
